<template>
	<div class="dropdown-icon">
		<i class="fa fa-ellipsis-v ellipsis"></i>
		<div class="dropdown-content">
			<div class="dropdown-item-group">
				<div class="dropdown-item" @click="showBookedMeetings()">My Booked Meetings</div>
				<div class="dropdown-item" @click="showIncomingPendingMeetings()">My Pending Meetings</div>
				<div class="dropdown-item" @click="showPendingMeetings()">My Outgoing Pending Meetings</div>
				<div class="dropdown-item" @click="handlePrintMP()">Print My Meetings</div>
			</div>
			<div class="dropdown-item-group">
				<div class="dropdown-item" @click="showAttendees()">Attending Members</div>
				<div class="dropdown-item" @click="showCompanies()">Attending Companies</div>
			</div>
			<div class="dropdown-item-group">
				<div @click="showPanelDiscussion()" class="dropdown-item">Panel Discussion Sessions</div>
				<div @click="showAgenda()" class="dropdown-item">Event Agenda</div>
				<router-link :to="{ name: 'standardEvent', params: { slug: event.slug , eventId: event.id  } }" class="dropdown-item">Event Page</router-link>
			</div>
		</div>
		<EventAgendaModal ref="agenda-modal" />
		<EventAgendaModal ref="panel-discussion-modal" isPanelDiscussion />
	</div>
</template>

<script>
import EventAgendaModal from './EventAgendaModal.vue';

export default {
	name: 'MeetingPlannerOptions',
	components: {
		EventAgendaModal,
	},
	methods: {
		showAttendees() {
			this.$emit('show-attendees');
		},
		showCompanies() {
			this.$emit('show-companies');
		},
		handlePrintMP() {
			this.$emit('print-mp');
		},
		showBookedMeetings() {
			this.$emit('show-booked-meetings');
		},
		showPendingMeetings() {
			this.$emit('show-pending-meetings');
		},
		showAgenda() {
			this.$refs['agenda-modal'].$refs.modal.show();
		},
		showPanelDiscussion() {
			this.$refs['panel-discussion-modal'].$refs.modal.show();
		},
		showIncomingPendingMeetings() {
			this.$emit('show-incoming-pending-meetings');
		}
	},
	props: {
		event: {
			type: Object,
			required: true
		}
	}
}

</script>

<style scoped>
i.ellipsis {
	font-size: 18px;
	color: #fff;
	cursor: pointer;
}

.dropdown-icon {
	cursor: pointer;
	padding: 5px 15px !important;
	background-color: #bd9d5f;
	border-radius: 0 10px 10px 10px;
	position: relative;
}

.dropdown-content {
	position: absolute;
	top: 100%;
	right: 0;
	background-color: #fff;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
	display: none;
	padding: 8px 8px 0 8px;
	z-index: 100;
}

.dropdown-item-group:not(:last-child) {
	border-top-left-radius: 0;
	margin-bottom: 7px;
	color: #333;
	border-bottom: 1px solid #efefef;
	padding-bottom: 8px;

}

.dropdown-item {
	color: #333;
	cursor: pointer;
	font-size: 14px;
	text-align: left;
	font-family: 'Montserrat', sans-serif;
	padding: .3rem 1.5rem !important;
}

.dropdown-item:hover {
	color: #bd9d5f;
}

.dropdown-icon:hover .dropdown-content {
	display: block;
}

a.dropdown-item:hover {
	color: #bd9d5f;
}

.dropdown-item.router-link-exact-active {
	color: #bd9d5f;
}

.dropdown-item.active, .dropdown-item:active, .dropdown-item:focus {
	background-color: #f8f9fa !important;
}
</style>