<template>
	<b-modal ref="modal" hide-footer :title="isPanelDiscussion ? 'Panel Sessions' : 'Agenda'">
		<div class="agenda-container">
			<Agenda expanseByDefault :isPanelDiscussion="isPanelDiscussion" />
		</div>
	</b-modal>
</template>

<script>
import Agenda from '../../events/StandardEvent/Agenda.vue';

export default {
	name: 'EventAgendaModal',
	components: {
		Agenda
	},
	props: {
		isPanelDiscussion: {
			type: Boolean,
			default: false
		}
	}
}
</script>

<style scoped>
.agenda-container {
	padding: 20px;
}
</style>