<template>
  <b-container fluid v-if="isActiveSection">
    <b-row class="forTags" v-if="sessions.length">
      <div class="agenda-button">
        <p
          class="display"
          @click="getAgendaHours()"
        >Show All</p>
        <button
          v-for="({id, color, name}) in sessions"
          :key="id"
          :style="`background-color: ${id === selectedLabel ? '#fff' : color};
            color: ${id === selectedLabel ? color : '#fff'};
            border-color: ${id === selectedLabel ? color : 'transparent !important'}`"
          @click="selectEventLabel(id)"
        >
          {{ name }}
        </button>
      </div>
    </b-row>
    <b-row  v-for="(day, index) in days" :key="day.id" class="agenda-section">
      <b-row no-gutters
        v-if="index !== 0 || (index === 0 && getAgendaHours(day.url).length)"
        @click="showHours(index)"
        class="agenda-date-first-row"
      >
        <div class="agenda-date-first-col">
          <p class="agenda-week-day">{{ agendaWeek(day.date) }}</p>
          <p class="agenda-day" v-if="!isMobileScreen">{{ agendaDay(day.date) }}</p>
          <p class="agenda-mounth" v-if="!isMobileScreen">{{ agendaMounth(day.date) }}</p>
          <p v-if="isMobileScreen" class="m-0 mt-0.7">{{ agendaDay(day.date) }} {{ agendaMounth(day.date) }}</p>
        </div>
        <div class="agenda-day-index"  v-if="index === 0">Pre-Event Day </div>
        <div class="agenda-day-index" v-else>DAY {{ index }} </div>
        <div id="up-down">
          <div v-if="days[index].show">
            <i id="agenda-up-icon" class="fas fa-angle-up"></i>
          </div>
          <div v-else>
            <i id="agenda-up-icon" class="fas fa-angle-down"></i>
          </div>
        </div>
      </b-row>
      <AgendaHours
        :agendaHours="getAgendaHours(day.url)"
        v-if="days[index].show"
        :url="url"
      />
    </b-row>
  </b-container>
  <div v-else style="text-align: center; color: #BD9D5F">The section is not available now and coming soon...</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import AgendaHours from './AgendaHours.vue';

export default {
  name: 'Agenda',
  components: {
    AgendaHours,
  },
  props:  {
    isPanelDiscussion: {
      type: Boolean,
      default: false,
    },
    expanseByDefault: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      days: [],
      agendaHours: [],
      selectedLabel: null,
      url: null,
    };
  },
  computed: {
    ...mapGetters({
      sessions: 'events/fetchKnowledgeSharingSession',
      event: 'events/fetchEvent',
      isMobileScreen: 'isMobileScreen',
    }),
    isActiveSection() {
      return this.event && this.event.available_sections ? this.event.available_sections.includes('agenda') : false;
    },
  },
  async mounted() {
    const { eventId, day, agendaUrl } = this.$route.params;
    this.url = agendaUrl;
    if (eventId) {
      const days = await this.fetchMeetingPlannerDays(eventId);
      this.days = this.$lodash.cloneDeep(days);
      this.agendaHours = await this.fetchAgenda(this.$route.params.eventId);
      if (this.isPanelDiscussion) {
        this.agendaHours = [...this.agendaHours].filter(({ type }) => type === 'panel_session');
      }
      await this.fetchKnowledgeSharingSession(this.$route.params.eventId);
    }
    if (day) this.showHours(null, day);
    if (this.expanseByDefault) {
      this.days.forEach((day, index) => {
        this.showHours(index);
      });
    }
  },
  methods: {
    ...mapActions({
      fetchMeetingPlannerDays: 'events/fetchMeetingPlannerDays',
      fetchAgenda: 'events/fetchAgenda',
      fetchKnowledgeSharingSession: 'events/fetchKnowledgeSharingSession',
    }),
    agendaWeek(date) {
      return moment(date).format(`${this.isMobileScreen ? 'ddd' : 'dddd'}`, 'en');
    },
    agendaDay(date) {
      return moment(date).format('DD', 'en');
    },
    agendaMounth(date) {
      return moment(date).format('MMM', 'en');
    },
    getAgendaHours(dayUrl) {
      if (!dayUrl) { this.selectedLabel = null; }
      return this.selectedLabel
        ? this.agendaHours.filter(({ day, labels }) => day === dayUrl && labels.filter(({ id }) => id === this.selectedLabel).length)
        : this.agendaHours.filter(({ day }) => day === dayUrl);
    },
    showHours(index, date) {
      const days = this.$lodash.cloneDeep(this.days);
      if (date) {
        this.days.forEach((day, i) => (day.date === date ? days[i].show = true : null));
      }
      if (days.length === 1) { days[index].show = true; }
      if (this.$lodash.isNumber(index)) {
        days[index].show = !days[index].show;
        this.url = null;
      }
      this.days = days;
    },
    selectEventLabel(id) {
      this.selectedLabel = id;
    },
  },
};
</script>

<style>
.agenda-expanded > div > div {
  word-wrap: break-word;
}
p {
  color: inherit;
}
</style>
<style scoped>
.forTags {
  background: white;
  border-radius: 0 10px 10px 10px;
  padding-bottom: 5px;
}
.agenda-button {
  float: right;
  padding: 4px 8px;
}
.agenda-button > button {
  font-size: 11px;
  color: white;
  margin: 4px 2px 0 4px;
  padding: 3px 5px 2px;
  border-radius: 3px
}
.agenda-button > button:focus {
  outline: none;
  box-shadow: none;
}
.agenda-date-first-row {
  background-color: #666666;
  color: #fff;
  margin-bottom: 0px;
  cursor: pointer;
  margin-top: 8px;
  border-radius: 0 10px 10px 10px;
  width: 100%;
  position: relative;
}
.agenda-date-first-row:hover {
  opacity: 0.5;
}
.agenda-date-first-col {
  border-right: 1px solid #ebebebeb;
  color: inherit;
  max-width: 60px;
  min-width: 60px;
  padding: 5px 0;
  text-align: center;
}
.agenda-day-index {
  color: inherit;
  font-size: 24px;
  text-transform: uppercase;
  padding-left: 10px;
  align-self: center;
}
.agenda-text {
  width: 380px;
  font-size: 13px;
  line-height: 29px;
}
.agenda-text-2 {
  width: 280px;
  font-size: 13px;
  line-height: 29px;
}
.agenda-section .container-fluid {
    margin-left: 60px;
    margin-right: 10px;
}
/* media screen mobile here */
@media (max-width: 768px) {
  .agenda-section .container-fluid {
    margin-left: 0;
    margin-right: 0;
  }
}
.second-col {
  max-width: 50px !important;
  background: white;
  border-right: 1px solid #e8e8e8;
  padding: 8px 6px 3px 5px;
  font-size: 11px;
}
.second-row {
  background-color: rgb(255, 255, 255);
  height: 50px;
  box-shadow: 0px 0px 1px 0px #5d5d5d;
  margin-bottom: -8px !important;
  border-bottom: 1px solid #f3f3f3;
  margin-top: -3px;
}
.btn-secondary:hover {
  opacity: 0.5;
}
.btn-secondary:focus {
  outline: none !important;
  box-shadow: unset !important;
}
.third-row {
  background-color: #f3f3f3;
  margin-left: 35px;
}
.cover-photo > img {
  width: 100%;
  height: 100px;
  object-fit: cover;
}
.agenda-expanded {
  padding: 14px 1px 9px 2px;
}
.agenda-btn {
  background: #BD9D5F;
  border: unset;
  color: white;
}
.sponsors-img {
  width: 100%;
  height: 80px;
  object-fit: contain;
}
.sponsors-col {
  border: 1px solid rgb(233, 233, 233);
  padding: 0;
  margin-left: 12px;
  padding: 0px 12px 1px 12px;
  background: white;
}
.sponsor-txt {
  margin: 5px 0px 6px 125px;
  color: #767676;
  text-transform: capitalize;
  font-size: 13px;
}
#agenda-icon {
  color: #BD9D5F;
}
#agenda-up-icon {
  color: white;
  margin: 12px -8px;
}
#up-down {
  position: absolute;
  top: 3px;
  right: 14px;
}
.topics > ul > li {
  font-size: 14px;
  font-style: italic;
}
.agenda-speaker-img {
  border: 1px solid #dfdfdfeb;
  max-width: 233px;
  margin: 0px 33px 19px 0px;
  height: 62px;
  padding: 0;
  padding-left: 15px;
  background: white;
}
.dg-cloud {
  background: #740000;
  color: white;
  font-size: 10px;
  padding: 0px 4px 0px 4px;
  border: unset;
  float: right;
  margin: 13px -30px;
  line-height: 19px;
}
.display {
  float: left;
  font-size: 12px;
  margin: 4px 6px 0px 0px;
  color: #555755;
  cursor: pointer;
}

.agenda-week-day {
  font-size: 9px;
  margin: 0 0 3px 0;
  line-height: 1;
}
.agenda-day {
  font-size: 12px;
  margin: 0;
  line-height: 1;
}
.agenda-mounth {
  font-size: 14px;
  margin: 0;
  line-height: 1;
}
@media (max-width: 768px) {
  .forTags {
    border-radius: 0 10px 10px 10px;
  }
  .agenda-button > button {
    font-size: 8px;
    margin: 2px;
    padding: 4px;
    border-radius: 8px 8px 8px 0;
  }
  .agenda-date-first-row {
    background-color: #fff;
    color: #333;
    margin-top: 0;
    height: 48px;
    align-items: center;
  }
  .agenda-section {
    border-radius: 0 10px 10px 10px;
    overflow: hidden;
    margin-top: 8px;
  }
  #agenda-up-icon {
    color: #333;
  }
  .agenda-date-first-col {
    display: grid;
    grid-template-columns: 17px 63px;
    align-self: center;
    justify-self: center;
    padding: 0;
    border-right: unset;
    width: 79px;
    max-width: 80px;
  }
  .agenda-date-first-col>p {
    font-size: 14px;
  }
  .agenda-week-day {
    transform: rotate(270deg);
    text-transform: uppercase;
    border-bottom: 1px solid #ebebeb;
    width: 48px;
    margin: 2px 0px 0px -15px;
    line-height: 1.5;
    height: 17px;
    padding: 0px 0px 20px 0px;
  }
  .agenda-day,
  .agenda-mounth {
    margin: 2px -3px 0 0;
  }
  .agenda-day-index {
    border-left: 1px solid #ebebeb;
    padding: 6px 8px 0;
    font-size: 24px;
    height: 48px;
  }
}
</style>
